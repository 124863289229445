import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import Breadcrumb from '../../../../components/Breadcrumb';

const DataAnalyticsPlatforms = ({pageContext}) => (
	<Layout>
		<SEO title="Data analytics platforms" />
		<Hero>
			<Breadcrumb crumbs={pageContext?.crumbs} />
			<h1>Online exploration and analytical platforms</h1>
		</Hero>
		<Block light>
			<Content>
				<Subnavigation>
					{/*<Anchors>*/}
					{/*	<a href={'#target-beneficiaries'}>Target beneficiaries</a>*/}
					{/*	<a href={'#service-elements'}>Service elements</a>*/}
					{/*	<a href={'#target-application-domains'}>*/}
					{/*		Target application domains*/}
					{/*	</a>*/}
					{/*</Anchors>*/}

					<PersonBadge
						note={pageContext.service.contactNote}
						email={pageContext.service.contactPerson}
					/>
				</Subnavigation>
			</Content>
		</Block>
		<Block light>
			<Content>
				<p>
					While a vast amount of spatial data is available from various sources
					(such as OpenData, EO imagery, statistical data or citizen science
					data), considerable effort is still needed to derive required
					information out of these datasets. Often, it results in situations
					that data is not effectively used. Technical & information support and
					actual decision making levels are often largely disconnected, which
					prevents desired effective information flows. Loops between the
					experts who generate the data and the ones who use it to gain new
					information to make decisions have to be therefore streamlined.
				</p>
				<p>
					We provide an Open Source framework allowing rapid development of web
					portals and applications supporting interactive exploration and
					analysis transforming rather complex data into standard information,
					offering user-friendly tools to understand, describe, compare and
					explain environmental changes in socio-economic context. In order to
					achieve this level of flexibility, functionalities go far beyond the
					traditional concept of data provision or simple web mapping. The focus
					is on predefined themes, reducing complexity, provision of an
					intuitive interface and instant response in a general concept that is
					taken from business analytic. Our web applications integrate all main
					standard presentation modes - maps, tables and charts in an
					interlinked manner. User is also free to choose the most appropriate
					level of granularity for analysis or define his own analytical units.
					Analytical views on the data can be stored, exported (e.g. for
					reporting purposes) or shared for co-creation with other users using
					live URL links.
				</p>
			</Content>
		</Block>
	</Layout>
);

DataAnalyticsPlatforms.propTypes = {
	pageContext: PropTypes.object.isRequired,
};
export default DataAnalyticsPlatforms;
